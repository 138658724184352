const loadGoogleMapsApi = require('load-google-maps-api');

class Map {
  
    static loadGoogleMapsApi() {
      return loadGoogleMapsApi({ key: "AIzaSyD5Zo4FCitnTgCGBvTBvzgj5lK87s07X1A" });
    }
    static createMap(googleMaps, mapElement) {
        var mapData    = { pos: { lat: 35.667216, lng: 139.696695 }, zoom: 14};
        //マーカー情報(情報ウインドウ含む)
        var markerData = [
            { pos: { lat: 35.667216, lng: 139.696695 }, title: "渋谷防災フェス", icon:{url: "http://shibuya-bosai.sakura.ne.jp/2019/wp-content/themes/sbf/img/ico_map@2x.png", scaledSize: new google.maps.Size(70, 91)}, infoWindowOpen: false},
        ];
        //地図の設定と作成
        var map = new google.maps.Map(document.getElementById('map'), {
            center: mapData.pos,
            zoom:   mapData.zoom,
            mapTypeControl: false,
            panControl: false,
            scaleControl: false,
            streetViewControl: false,
            styles: [
                {
                    "featureType": "landscape",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#009e41"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -73
                        },
                        {
                            "lightness": 40
                        },
                        {
                            "gamma": 1
                        },
                        {
                            "hue": "#00ffd8"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "all",
                    "stylers": [
                        {
                            "hue": "#fbff00"
                        },
                        {
                            "gamma": 1
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "all",
                    "stylers": [
                        {
                            "hue": "#00fffd"
                        },
                        {
                            "lightness": 30
                        },
                        {
                            "gamma": 1
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "hue": "#00BFFF"
                        },
                        {
                            "saturation": 6
                        },
                        {
                            "lightness": 8
                        },
                        {
                            "gamma": 1
                        }
                    ]
                }
            ]
        });
        for( var i=0; i < markerData.length; i++ )
        {
            //即時関数
            (function(){
                //マーカーの設定と作成
                var marker = new google.maps.Marker({
                    position: markerData[i].pos,
                    title:    markerData[i].title,
                    icon:     markerData[i].icon,
                    map: map
                });
                /*if( markerData[i].infoWindowContent )
                {
                    //情報ウインドウの設定と作成
                    var infoWindow = new google.maps.InfoWindow({
                        content: markerData[i].infoWindowContent
                    });
                    //情報ウインドウのオープンをマーカーのクリックイベントに登録
                    marker.addListener('click', function(){
                        infoWindow.open(map, marker);
                    });
                    //情報ウインドウのオープン
                    if( markerData[i].infoWindowOpen )
                    {
                        infoWindow.open(map, marker);
                    }
                }*/
            }());
        }
    }
  }
export { Map };
